import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";
import { useProfilePictureQuery } from "src/store/services/profile/profileApi";

const useProfilePicture = (id?: any) => {
  const {
    data: profilePicture,
    refetch: refetchProfilePicture,
    isLoading: profilePictureIsLoading,
    isSuccess: profilePictureIsSuccess,
    isError: profilePictureIsError,
    isFetching: profilePictureIsFetching
  } = useProfilePictureQuery(!id ? skipToken : `${id}`);

  return useMemo(() => {
    return {
      profilePicture,
      refetchProfilePicture,
      profilePictureIsLoading,
      profilePictureIsSuccess,
      profilePictureIsError,
      profilePictureIsFetching
    };
  }, [
    profilePicture,
    refetchProfilePicture,
    profilePictureIsLoading,
    profilePictureIsSuccess,
    profilePictureIsError,
    profilePictureIsFetching
  ]);
};

export default useProfilePicture;
