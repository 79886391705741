import React, { FC, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import type { MenuProps } from "antd";
import { Layout, Menu, Typography, Card, Input, Select, DatePicker, Space, Button, Table, Row, Dropdown, Avatar, Badge } from "antd";
import { MenuOutlined, UserOutlined, SearchOutlined, DownOutlined, SmileOutlined } from "@ant-design/icons";

// project import
import { logoIcon, alertIcon, avatarIcon, selectIcon, settingIcon, homeIcon, pen, signout, pro, down } from "src/images";
import { LinkStyled, TextStyled, IconStyled, MenuStyled } from "src/components/styles";

import { LanguagePicker } from "src/components/common/LanguagePicker/LanguagePicker";
import { useTranslation } from "react-i18next";
import { media } from "src/styles/themes/constants";
import Notifications from "src/components/common/Notications/Notifications";
import UserMenu from "src/components/common/UserMenu/UserMenu";
import { useLanguage } from "src/hooks/useLanguage";
import { useGetNotiQuery } from "src/store/services/notiApi";
import { Noti } from "src/models/api/noti.model";


const MainLayoutHeader = (props: any) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const [mobile, setMobile] = useState(window.innerWidth <= 992);
  const [offset, setOffset] = useState(1);
  const limit = 6;
  const { data: notiData, refetch, isFetching: notiLoading } = useGetNotiQuery({
    offset: offset,
    limit: limit
  });
  const [notiArrayData, setNotiArrayData] = useState<Noti[]>([]);
  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 992);
  };

  const copyDeepObject = (obj : Object) => {
    return JSON.parse(JSON.stringify(obj));
  }

  const triggerFetchNoti = (index?: number) => {
    if (index) {
      let temp: Noti[] = [...notiArrayData];
      let temp2 = copyDeepObject(temp[index - 1])
      temp2.is_read = true
      temp[index - 1] = temp2
      setNotiArrayData(temp);
    }
  };

  const triggerFetchReadAll = () => {
    setNotiArrayData([]);
    setOffset(1);
    // refetch();
  }

  const triggerBottomNoti = () => {
    // setLoading(true)
    if(notiLoading) return;
    if (notiData?.total_message === notiArrayData?.length) return;
    setOffset((prev) => prev + 1);
    // refetch();
  };

  useEffect(() => {
    if (notiData?.message) {
      setNotiArrayData((prev) => [...prev, ...notiData?.message]);
    }
  }, [notiData])



  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <HeaderCustomStyled>
        {mobile ? (
          <></>
        ) : (
          <SpaceCustomStyled size={40}>
            <LinkStyled to="/loan/request/list">
              <IconStyled src={logoIcon} alt="logoIcon" style={{ width: "160px" }} />
            </LinkStyled>
            {/* <SearchStyled placeholder={t("layout.dev.header.search") ?? ""} suffix={<SearchOutlined />} /> */}
          </SpaceCustomStyled>
        )}
        <Button className="sidebar-toggler" type="link" onClick={props.onClick}>
          <MenuOutlinedCustomStyled style={{ color: "var(--white-color)" }} />
        </Button>
        <Space size={20}>
          {props?.profile && (
            <UserMenu>
              <LinkStyled to={"#"}>
                <Space direction={"horizontal"}>
                  <>
                    {!props?.profilePictureIsFetching && props?.profilePictureIsSuccess ? (
                      <IconStyled
                        src={`${props?.profilePicture}`}
                        alt={`${props?.profilePicture}`}
                        style={{ width: "40px", borderRadius: "20px" }}
                      />
                    ) : (
                      !props?.profilePictureIsFetching && (
                        <Avatar style={{ backgroundColor: "#bfbfbf" }} icon={<UserOutlined className="small_icon" />} />
                      )
                    )}
                    <Space>
                      <TextStyled style={{ fontSize: "16px" }}>
                        {language === "th"
                          ? `${props?.profile?.user?.firstname_th ?? props?.profile?.user?.firstname_en} ${props?.profile?.user?.lastname_th ?? props?.profile?.user?.lastname_en
                          }`
                          : `${props?.profile?.user?.firstname_en ?? props?.profile?.user?.firstname_th} ${props?.profile?.user?.lastname_en ?? props?.profile?.user?.lastname_th
                          }`}
                      </TextStyled>
                      <img src={down} />
                    </Space>
                  </>
                </Space>
              </LinkStyled>
            </UserMenu>
          )}
          <Notifications
            loading={notiLoading}
            notiDataList={notiArrayData || []}
            notiUnreadCount={notiData?.un_read_count || 0}
            triggerFetch={(e?: number) => triggerFetchNoti(e)}
            triggerFetchReadAll={triggerFetchReadAll}
            triggerBottom={triggerBottomNoti}
          >
            <LinkStyled to={"#"}>
              <Badge count={notiData?.un_read_count}>
                <IconStyled src={alertIcon} alt={"alertIcon"} />
              </Badge>
            </LinkStyled>
          </Notifications>
          <LanguagePicker />
        </Space>
      </HeaderCustomStyled>
    </>
  );
};

export default MainLayoutHeader;

const HeaderCustomStyled = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  background-image: var(--header-color);
  /* width: 100%; */

  /* @media screen and (${media.sm}) {
    minwidth: 576px;
  } */
`;
const SpaceCustomStyled = styled(Space)`
  display: flex;
  overflow: hidden;
  @media only screen and (${media.lg}) {
    display: none;
  }
`;
const MenuOutlinedCustomStyled = styled(MenuOutlined)`
  & * {
    font-size: 26px;
    margin: -2px 0 0 -20px;
  }
`;
const SearchStyled = styled(Input)`
  display: flex;
  align-items: center;
  padding-inline: 20px;
  width: 400px;
  height: 41px;
  border-radius: 50px;
  background: var(--white-color);
  color: var(--input-color);
  border: 0;

  @media screen and (${media.xl}) {
    width: 300px;
  }
`;
const DropdownStyled = styled(Dropdown)`
  & .ant-dropdown .ant-dropdown-menu {
    padding: 10px !important;
  }
`;
