import React, { useEffect, useState } from "react";
import { Radio, Space } from "antd";
import { useLanguage } from "src/hooks/useLanguage";
import { TextStyled } from "src/components/styles";
import { fontWeight } from "src/styles/themes/constants";
import styled, { css } from "styled-components";

interface TextCustomProps {
  status?: number;
  page?: string;
}

export const LanguagePicker = (props: TextCustomProps) => {
  const { language, setLanguage } = useLanguage();

  return (
    <>
      {props.page === "auth" ? (
        <Space>
          <TextCustom
            status={language === "th" ? 1 : 0}
            style={{ color: language === "th" ? "var(--primary-color)" : "#ADB5BD" }}
            onClick={() => setLanguage("th")}
          >
            TH
          </TextCustom>
          <TextStyled style={{ cursor: "default", color: "var(--primary-color)" }}>I</TextStyled>
          <TextCustom
            status={language === "eng" ? 1 : 0}
            style={{ color: language === "eng" ? "var(--primary-color)" : "#ADB5BD" }}
            onClick={() => setLanguage("eng")}
          >
            EN
          </TextCustom>
        </Space>
      ) : (
        <Space>
          <TextCustom status={language === "th" ? 1 : 0} onClick={() => setLanguage("th")}>
            TH
          </TextCustom>
          <TextStyled style={{ cursor: "default", color: "var(--white-color)" }}>I</TextStyled>
          <TextCustom status={language === "eng" ? 1 : 0} onClick={() => setLanguage("eng")}>
            EN
          </TextCustom>
        </Space>
      )}
    </>
  );
};

const TextCustom = styled(TextStyled)<TextCustomProps>`
  cursor: pointer;
  font-weight: ${(props) => (props.status === 1 ? `${fontWeight.semibold}` : `${fontWeight.regular}`)};
`;
