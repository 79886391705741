import React, { FC, useState } from "react";
import { Row, Col, Avatar, Badge, Popover, Spin } from "antd";
import styled from "styled-components";
import { fontWeight } from "src/styles/themes/constants";
import { TextStyled } from "src/components/styles";
import { readAllIcon } from "src/images";
import { WithChildrenProps } from "src/models/types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useReadNotiMutation, useReadAllNotiMutation } from "src/store/services/notiApi";
import dayjs from "dayjs";
import { Noti } from "src/models/api/noti.model";
interface Props {
  loading: boolean;
  notiDataList: Noti[];
  notiUnreadCount: number;
  triggerFetch: (index?: number) => void;
  triggerFetchReadAll: () => void;
  triggerBottom: () => void;
}

const Notifications: FC<WithChildrenProps<Props>> = ({ loading, notiDataList, triggerFetch, triggerFetchReadAll, triggerBottom, notiUnreadCount, children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [readNoti] = useReadNotiMutation();
  const [readAllNoti] = useReadAllNotiMutation();
  const [open, setOpen] = useState(false);
  const openNotificationPage = () => {
    navigate("/notifications");
    hide();
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleNotiRead = (id: number, index: number, isRead: boolean) => {
    if (isRead === true) return;
    readNoti(id).then((res) => triggerFetch(index + 1));
  };

  const handleScroll = (event: any) => {
    const bottom = Math.ceil(event.target.scrollHeight - event.target.scrollTop) === event.target.clientHeight;
    if (bottom) {
      triggerBottom();
    }
  };
  const handleNotiReadAll = () => {
    readAllNoti({ type: null }).then((res) => triggerFetchReadAll());
  };

  // ------------------------------------- Develop Page ------------------------------
  const contentNotification = (
    <>
      <div style={{ width: "100%", maxHeight: "380px", overflowY: "scroll" , overflowX: "hidden"}} onScroll={handleScroll}>
        {notiDataList?.length > 0 &&
          notiDataList.map((element, index) => (
            <CardCustom
              onClick={() => {
                handleNotiRead(element.id, index, element.is_read);
                navigate(`/loan/request/${element.loan_id}/roomplan`);
              }}
              key={index}
            >
              <Row align={"middle"} gutter={[1, 8]}>
                <Col span={3}>
                  <Badge dot={!element?.is_read}>
                    <Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel`} />
                  </Badge>
                </Col>
                <Col span={15}>
                  <TextCustom>{element.title}</TextCustom>
                  <br />
                  <TextTopicNoti>{element.description}</TextTopicNoti>
                </Col>
                <Col span={4}>
                  <SpanTime>{dayjs(element.create_date).fromNow()}</SpanTime>
                </Col>
              </Row>
              <DividerCustom />
            </CardCustom>
          ))}
        <Row justify={"center"}>
          {
            loading && <Spin />
          }

        </Row>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
        <TextViewAllNoti onClick={openNotificationPage}>{t("page.notifications.layout.popover.viewAll")}</TextViewAllNoti>
      </div>
    </>
  );
  const titleNotificaations = (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <TextTitleNoti>{t("page.notifications.layout.popover.notification")}</TextTitleNoti>
        {notiUnreadCount > 0 && (
          <TextReadAll onClick={handleNotiReadAll}>
            <img src={readAllIcon}></img> {t("page.notifications.layout.popover.readAll")}
          </TextReadAll>
        )}
      </div>
      <DividerCustom />
    </>
  );

  const popover = (
    <Popover
      placement="bottomRight"
      title={titleNotificaations}
      content={contentNotification}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      {children}
    </Popover>
  );

  return popover;
};

export default Notifications;

const TextCustom = styled(TextStyled)`
  color: var(--input-color);
  font-weight: ${fontWeight.semibold};
`;

const TextTitleNoti = styled(TextStyled)`
  color: var(--title-color);
  font-weight: ${fontWeight.bold};
`;

const DividerCustom = styled.hr`
  border: 1px solid #e9ecef;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TextReadAll = styled(TextStyled)`
  color: var(--primary-color);
  font-weight: ${fontWeight.semibold};
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  :hover {
    background-color: #f4f5fd !important;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
  }
`;

const TextTopicNoti = styled(TextStyled)`
  font-weight: ${fontWeight.regular};
  font-size: 12px;
  color: var(--label-color);
`;

const SpanTime = styled(TextStyled)`
  color: var(--gray-color);
  font-weight: ${fontWeight.regular};
  font-size: 13px;
`;

const TextViewAllNoti = styled(TextStyled)`
  font-weight: ${fontWeight.semibold};
  font-size: 12px;
  color: var(--primary-color);
  cursor: pointer;
`;

const CardCustom = styled.div`
  :hover {
    cursor: pointer;
  }
`