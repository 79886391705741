import { RequestReport, ResponseReport } from "src/models/api/reports.model";
import { api } from "./api";

export const reportsApi = api.enhanceEndpoints({ addTagTypes: [] }).injectEndpoints({

    endpoints: (builder) => ({
        reportApprove: builder.query<ResponseReport, RequestReport & { project_code: string; company_code: string; bank_code: string }>({
            query: (Query) => ({
                url: "internals/report/approve",
                method: "POST",
                body: Query
            })
        }),
        reportBankStatus: builder.query<ResponseReport, RequestReport & { company_code:string; project_code: string; bank_code: string; status_code: string; transfer_start_date: string; transfer_end_date: string; }>({
            query: (Query) => ({
                url: "internals/report/bank-status",
                method: "POST",
                body: Query
            })
        }),
        reportFollowLoan: builder.query<ResponseReport, RequestReport & { project_code: string; bank_code: string; status_code: string; sub_status_code: string;}>({
            query: (Query) => ({
                url: "internals/report/follow-loan",
                method: "POST",
                body: Query
            })
        }),
        reportCompareCredit: builder.query<ResponseReport, RequestReport & { company_code:string; project_code: string; bank_code: string; }>({
            query: (Query) => ({
                url: "internals/report/compare-credit",
                method: "POST",
                body: Query
            })
        }),
        reportLoanByBank: builder.query<ResponseReport, RequestReport & {  
          project_code: string; 
          bank_code: string; 
          company_code: string; 
          transfer_start_date: string;
          transfer_end_date: string;
        }>({
            query: (Query) => ({
                url: "internals/report/loan-by-bank",
                method: "POST",
                body: Query
            })
        }),
        reportSLA: builder.query<ResponseReport, RequestReport & { 
          company_code: string; 
          project_code: string; 
          bank_code: string; 
          status_code: string; 
          transfer_start_date: string; 
          transfer_end_date: string;
          user_id:string;
        }>({
            query: (Query) => ({
                url: "internals/report/sla",
                method: "POST",
                body: Query
            })
        }),
   reportExloanSummary: builder.query<ResponseReport, RequestReport & { project_code: string; company_code: string }>({
      query: (Query) => ({
        url: "internals/report/ex-report/loan-summary",
        method: "POST",
        body: Query
      })
    }),
    reportExStatusSummary: builder.query<ResponseReport, RequestReport & { project_code: string; company_code: string }>({
      query: (Query) => ({
        url: "internals/report/ex-report/status-summary",
        method: "POST",
        body: Query
      })
    }),
    reportExUnitSummary: builder.query<ResponseReport, RequestReport & { project_code: string; status: string; bank_code: string }>({
      query: (Query) => ({
        url: "internals/report/ex-report/unit-summary",
        method: "POST",
        body: Query
      })
    }),
    reportExSLA: builder.query<ResponseReport, RequestReport & { company_code: string; user_id: string }>({
      query: (Query) => ({
        url: "internals/report/ex-report/sla",
        method: "POST",
        body: Query
      })
    }),
    reportExLoanByUser: builder.query<ResponseReport, RequestReport & { project_code: string; company_code: string; user_id: string }>({
      query: (Query) => ({
        url: "internals/report/ex-report/loan-by-user",
        method: "POST",
        body: Query
      })
    })
  })
});

export const {
    useLazyReportApproveQuery,
    useLazyReportBankStatusQuery,
    useLazyReportFollowLoanQuery,
    useLazyReportCompareCreditQuery,
    useLazyReportLoanByBankQuery,
    useLazyReportSLAQuery,
    useLazyReportExloanSummaryQuery,
  useLazyReportExStatusSummaryQuery,
  useLazyReportExUnitSummaryQuery,
  useLazyReportExSLAQuery,
  useLazyReportExLoanByUserQuery
} = reportsApi

