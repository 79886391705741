import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

import { SetPassword } from "src/models/api/setPassword.model";

export type SetPasswordData = Omit<SetPassword, "confirmPassword">;

const baseQueryWithRetry = retry(fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_KEY }), { maxRetries: 1 });

export const setPasswordApi = createApi({
  baseQuery: baseQueryWithRetry,
  reducerPath: "setPasswordApi",
  tagTypes: ["SetPassword"],
  endpoints: (builder) => ({
    setPassword: builder.mutation<void, SetPasswordData>({
      query: (setPasswordData) => ({
        url: "user/set-password",
        method: "POST",
        body: setPasswordData
      })
    }),
    forgotPassword: builder.mutation<void, string>({
      query: (email) => ({
        url: "user/forgot-password",
        method: "POST",
        body: { email: email }
      })
    })
  })
});

export const { useSetPasswordMutation, useForgotPasswordMutation } = setPasswordApi;
