import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CheckboxOptionType } from "antd";
import { BankRequest } from "src/models/api/bankFiles.model";
import { api } from "src/store/services/api";
import { ResponseStatusMessage } from "src/models/api/loan.model";
//------------------------------------------- server จริง หรือ server ทดสอบ เรียก ผ่าน JWT (ต้อง login)
// import { api } from "./api";

// const apiAddTag = api.enhanceEndpoints({ addTagTypes: ["Bank"] });

// export const contactsApi = apiAddTag.injectEndpoints({

//------------------------------------------- simulation json server (ไม่ต้อง login)
export const banksApi = api.enhanceEndpoints({ addTagTypes: ["Bank"] }).injectEndpoints({
  // export const banksApi = createApi({
  //   baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_KEY }),
  //   reducerPath: "bankApi",
  //   tagTypes: ["Bank"],
  endpoints: (builder) => ({
    banks: builder.query<CheckboxOptionType[], void>({
      query: () => "/banks",
      transformResponse: (data: any[]) => data.map((v: any) => ({ value: v.id, label: v.name })),
      providesTags: ["Bank"]
    }),
    bankRequest: builder.query<BankRequest[], string[]>({
      query: (list) => "/bankRequest",
      transformResponse: (data: any[], _: any, value) =>
        data
          .filter((v) => {
            return value.includes(v.id);
          })
          .map((v: any) => ({
            id: v.requestId,
            name: v.bank,
            date: v.date,
            fileType: "App",
            files: "เอกสาร.pdf",
            status: v.status,
            isFiles: false,
            children: v.files.map((file: any) => ({
              id: file.id,
              name: file.name,
              date: "",
              fileType: file.type,
              files: file.name,
              status: file.status,
              isFiles: true
            }))
          })),
      providesTags: ["Bank"]
    }),
    
  })
});

export const { 
  useBanksQuery, 
  useLazyBankRequestQuery
} = banksApi;
