import { useMemo } from "react";
import {
  useProfileDevelopQuery,
  useProfileQuery,
  useSetChangePasswordMutation,
  useUpdateProfileDevelopMutation,
  useUpdateProfileMutation
} from "src/store/services/profile/profileApi";

const useProfile = () => {
  const { data, refetch, isLoading, fulfilledTimeStamp, error } = useProfileQuery();
  const { data: profileDevelop } = useProfileDevelopQuery();
  const [setChangePassword, setChangePasswordResult] = useSetChangePasswordMutation();
  const [updateProfile, updateProfileResult] = useUpdateProfileMutation();
  const [updateProfileDevelop, updateProfileDevelopResult] = useUpdateProfileDevelopMutation();

  return useMemo(() => {
    return {
      data,
      refetch,
      isLoading,
      fulfilledTimeStamp,
      error,
      setChangePassword,
      setChangePasswordResult,
      profileDevelop,
      updateProfile,
      updateProfileResult,
      updateProfileDevelop,
      updateProfileDevelopResult
    };
  }, [
    data,
    refetch,
    isLoading,
    fulfilledTimeStamp,
    error,
    setChangePassword,
    setChangePasswordResult,
    profileDevelop,
    updateProfile,
    updateProfileResult,
    updateProfileDevelop,
    updateProfileDevelopResult
  ]);
};

export default useProfile;
