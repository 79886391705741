import React, { FC, useEffect } from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { ConfigProvider } from "antd";
import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  createRoutesFromChildren,
  RouterProvider,
  Router
} from "react-router-dom";
import { useLanguage } from "./hooks/useLanguage";
import enUS from "antd/lib/locale/en_US";
import thTH from "antd/lib/locale/th_TH";
import GlobalStyle from "src/styles/GlobalStyle";
import Routes from "src/routers/routers";

const App: FC = () => {
  const router = Routes();
  const { language } = useLanguage();

  return (
    <>
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider theme={{ hashed: false }} locale={language === "th" ? thTH : enUS}>
          <RouterProvider router={router} />
        </ConfigProvider>
      </HelmetProvider>
    </>
  );
};

export default App;
