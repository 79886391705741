import {
  Prefix,
  Gender,
  Occupation,
  MarriedStatus,
  Nationality,
  District,
  SubDistrict,
  Province,
  Business,
  Bureau,
  Relationship,
  Country,
  Education,
  ResponseUserMaster,
  ResponseProjectMaster,
  DistrictQuery,
  SubDistrictQuery,
  Bank,
  ResponseStatusLoan,
  ResponseCompany,
  ResponseBankStatus,
  ResponseCustomerList,
  RequestCustomerList,
  ResponseBankGroup
} from "src/models/api/master.model";
import { api } from "./api";

interface Query {
  query: string;
}

export const masterApi = api.enhanceEndpoints({ addTagTypes: ["Master"] }).injectEndpoints({
  endpoints: (builder) => ({
    getPrefix: builder.query<Prefix[], Query>({
      query: (Query) => ({
        url: "internals/master-setting/prefix",
        method: "POST",
        body: Query
      })
    }),
    getGender: builder.query<Gender[], Query>({
      query: (Query) => ({
        url: "internals/master-setting/gender",
        method: "POST",
        body: Query
      })
    }),
    getOccupation: builder.query<Occupation[], Query>({
      query: (Query) => ({
        url: "internals/master-setting/occupation",
        method: "POST",
        body: Query
      })
    }),
    getMarriedStatus: builder.query<MarriedStatus[], Query>({
      query: (Query) => ({
        url: "internals/master-setting/married-status",
        method: "POST",
        body: Query
      })
    }),
    getNationality: builder.query<Nationality[], Query>({
      query: (Query) => ({
        url: "internals/master-setting/nationality",
        method: "POST",
        body: Query
      })
    }),
    getDistrict: builder.query<District[], DistrictQuery>({
      query: (Query) => ({
        url: "internals/master-setting/district",
        method: "POST",
        body: Query
      })
    }),
    getSubDistrict: builder.query<SubDistrict[], SubDistrictQuery>({
      query: (Query) => ({
        url: "internals/master-setting/sub-district",
        method: "POST",
        body: Query
      })
    }),
    getProvince: builder.query<Province[], Query>({
      query: (Query) => ({
        url: "internals/master-setting/province",
        method: "POST",
        body: Query
      })
    }),
    getCountry: builder.query<Country[], Query>({
      query: (Query) => ({
        url: "internals/master-setting/country",
        method: "POST",
        body: Query
      })
    }),
    getRelationship: builder.query<Relationship[], Query>({
      query: (Query) => ({
        url: "internals/master-setting/relationship",
        method: "POST",
        body: Query
      })
    }),
    getBureau: builder.query<Bureau[], Query>({
      query: (Query) => ({
        url: "internals/master-setting/bureau",
        method: "POST",
        body: Query
      })
    }),
    getBusiness: builder.query<Business[], Query>({
      query: (Query) => ({
        url: "internals/master-setting/business",
        method: "POST",
        body: Query
      })
    }),
    getEducation: builder.query<Education[], Query>({
      query: (Query) => ({
        url: "internals/master-setting/education",
        method: "POST",
        body: Query
      })
    }),
    getBank: builder.query<Bank[], Query>({
      query: (Query) => ({
        url: "internals/master-setting/bank",
        method: "POST",
        body: Query
      })
    }),
    getProject: builder.query<ResponseProjectMaster, void>({
      query: () => "internals/master/project"
    }),
    getUsers: builder.query<ResponseUserMaster, void>({
      query: () => ({ url: "internals/master/user/select", method: "POST" })
    }),
    getStatusLoan: builder.query<ResponseStatusLoan, void>({
      query: () => "internals/master/status/loan"
    }),
    getCompany: builder.query<ResponseCompany, void>({
      query: () => "internals/master/company"
    }),
    getBankStatus: builder.query<ResponseBankStatus, void>({
      query: () => "internals/master/status/bank"
    }),
    getCustomerList: builder.query<ResponseCustomerList, RequestCustomerList>({
      query: (Query) => ({
        url: "internals/loan/get/customer_lists",
        method: "POST",
        body: Query
      })
    }),
    getBankGroup : builder.query<ResponseBankGroup, void>({
      query: () => ({
        url: "internals/master/status/bank/group",
        method: "POST",
      })
    })
  })
});

export const {
  useGetPrefixQuery,
  useGetGenderQuery,
  useGetOccupationQuery,
  useGetMarriedStatusQuery,
  useGetNationalityQuery,
  useGetDistrictQuery,
  useGetSubDistrictQuery,
  useGetProvinceQuery,
  useGetCountryQuery,
  useGetRelationshipQuery,
  useGetBureauQuery,
  useGetBusinessQuery,
  useGetEducationQuery,
  useGetBankQuery,
  useGetProjectQuery,
  useGetUsersQuery,
  useGetStatusLoanQuery,
  useGetCompanyQuery,
  useGetBankStatusQuery,
  useGetCustomerListQuery,
  useGetBankGroupQuery
} = masterApi;
