import styled from "styled-components";
import { Input, InputNumber } from "antd";

export const InputStyled = styled(Input)`
  height: 39px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  color: var(--input-color);
  background: var(--white-color);
  &.ant-input {
    color: var(--input-color);
    background-color: var(--white-color);
  }
  &.ant-input[disabled],
  .ant-input-disabled {
    color: var(--input-color);
    background-color: #E9ECEF;
    border-color: #DEE2E6;
  }
  &.ant-input-disabled,
  .ant-input[disabled] {
    cursor: default;
    .ant-input-input {
      cursor: default;
    }
  }
`;
export const InputPrefixStyled = styled(Input).attrs(() => {
  return {
    size: "small"
  };
})`
  height: 31px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
  color: var(--input-color);
  background: var(--white-color);
  input.ant-input {
    background-color: transparent;
  }
`;

export const InputNumberStyled = styled(InputNumber).attrs(() => {
  return {
    size: "large"
  };
})`
  height: 39px;
  width: 100%;
  background: var(--white-color);
  .ant-input-number-group .ant-input-number-group-addon {
    border: 1px solid #6782ea;
    background-color: rgba(0, 0, 0, 0);
    color: #6782ea;
  }
  &.ant-input-number-disabled,
  .ant-input-number[disabled] {
    color: var(--input-color) !important;
    border-color: #DEE2E6;
    background-color: #E9ECEF;
    
  }
  &.ant-input-number-disabled .ant-input-number-input {
    cursor: default;
  }
`;
