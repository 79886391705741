import styled from "styled-components";
import { Checkbox, Button, Input, Form } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import loginBackground from "src/assets/images/login-bg.webp";
import authBackground from "src/images/admin/authBackground2.png";
import { media } from "src/styles/themes/constants";

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
`;

export const BackgroundWrapper = styled.div`
  min-width: 40%;
  height: 100%;
  background: url(${authBackground});
  background-size: cover;
  position: relative;
  opacity: 0.8;
  @media only screen and (${media.lg}) {
    min-width: 0;
    display: none;
  }
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
