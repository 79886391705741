import React, { useEffect, FC, useMemo, FunctionComponent } from "react";
import { useFetchTokenQuery, useLoginMutation } from "src/store/services/authApi";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "src/store/slice/authSlice";
import SuspenseLoader from "src/components/suspenseLoader";
import useAuth from "src/hooks/useAuth";
import { ModalError, ModalSuccess } from "src/components/modals/ModalsComponent";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import useUser from "src/hooks/useUser";

type Props = { children: React.ReactNode | any };

export const AuthChildren = ({ children }: Props) => {
  const { data: fetchToken, isLoading: tokenIsLoading } = useFetchTokenQuery();
  const navigate = useNavigate();

  if (process.env.REACT_APP_NODE_ENV !== "development") {
    if (tokenIsLoading) {
      <SuspenseLoader />;
    } else {
      if (fetchToken) {
        return children;
      } else {
        return navigate("/auth/login");
      }
    }
  } else {
    return children;
  }

  return;
};
