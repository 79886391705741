import styled, { css } from "styled-components";
import { useEffect, FC, useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu, Typography, Card, Input, Select, DatePicker, Space, Button, Table, Row, Drawer, Modal } from "antd";
import { AppstoreOutlined, CalendarOutlined, MailOutlined } from "@ant-design/icons";
import { media, fontWeight } from "src/styles/themes/constants";

// project import
import { logoIcon, alertIcon, avatarIcon, selectIcon, settingIcon, homeIcon } from "src/images";
import { totop, totopadmin } from "src/images/admin";
import { LinkStyled, TextStyled, IconStyled, MenuStyled, ButtonPrimaryStyled } from "src/components/styles";

import type { MenuProps, MenuTheme } from "antd/es/menu";
import MainLayoutHeader from "src/components/layouts/main/MainLayoutHeader";
import { useTranslation } from "react-i18next";
import { useScrollToTop, scrollToTop } from "src/hooks/useScrollToTop";
import useProfile from "src/hooks/useProfile";
import { useProfilePictureQuery, useProfileQuery } from "src/store/services/profile/profileApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useProfilePicture from "src/hooks/useProfile/useProfilePicture";
import SuspenseLoader from "src/components/suspenseLoader";
import { useConfigQuery } from "src/store/services/configApi";
import { useFetchTokenQuery } from "src/store/services/authApi";
import { ModalError } from "src/components/modals/ModalsComponent";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(label: React.ReactNode, key?: React.Key | null, children?: MenuItem[]): MenuItem {
  return {
    key,
    children,
    label
  } as MenuItem;
}

interface Props {
  hideSidebar?: boolean;
}

const MainLayout: FC<Props> = ({ hideSidebar }: Props) => {
  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const location = useLocation();
  const navigate = useNavigate();
  const { data: profile, refetch: refetchProfile } = useProfileQuery();
  const { profilePicture, refetchProfilePicture, profilePictureIsSuccess, profilePictureIsError, profilePictureIsFetching } =
    useProfilePicture(profile?.user.profile_id);
  const displayed = useScrollToTop(() => window.pageYOffset > 1);

  const items: MenuItem[] = [
    getItem(`${t("layout.dev.sider.loanRequest")}`, "/loan/request/list"),
    getItem(`${t("layout.dev.sider.loanStatus")}`, "/loan/case/list"),
    getItem(`${t("layout.dev.sider.report")}`, "/report"),
    profile?.user?.role_id === 1 || process.env.REACT_APP_NODE_ENV === "production-dev"
      ? getItem(`${t("layout.dev.sider.setting")}`, "/setting/user-management")
      : null
  ];

  const replaceLocationPathname = (locationPathname: string) => {
    if (locationPathname === "/" || locationPathname.slice(0, 13) === "/loan/request") {
      return "/loan/request/list";
    } else if (location.pathname.slice(0, 8) === "/setting") {
      return "/setting/user-management";
    } else if (location.pathname.slice(0, 12) === "/loan/status") {
      return "/loan/case/list";
    }
    return locationPathname;
  };

  const menuSider = (
    <MenuStyled
      mode="inline"
      selectedKeys={[replaceLocationPathname(location.pathname)]}
      items={items}
      onClick={({ key }) => {
        navigate(key);
        setVisible(false);
      }}
    />
  );

  const drawer = (
    <Drawer
      title={false}
      placement={placement === "right" ? "left" : "right"}
      closable={false}
      onClose={() => setVisible(false)}
      open={visible}
      key={placement === "right" ? "left" : "right"}
      width={330}
      className="drawer-sidebar"
      style={{ background: "var(--background-color)" }}
    >
      <Layout className="layout-dashboard" style={{ background: "var(--background-color)" }}>
        <Layout.Sider
          trigger={null}
          width={280}
          className="sider-primary ant-layout-sider-primary"
          style={{ background: "var(--background-color)" }}
        >
          <DivCustomStyled>
            <LinkStyled to={"#"}>
              <IconStyled src={homeIcon} alt={"homeIcon"} style={{ width: "110px" }} />
            </LinkStyled>
          </DivCustomStyled>
          {menuSider}
        </Layout.Sider>
      </Layout>
    </Drawer>
  );

  const openDrawer = () => setVisible(!visible);
  // useEffect(() => {
  //   if (error) {
  //     console.log(error);
  //     // ModalError({ modal: modal });
  //   }
  // }, [error]);

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <MainLayoutHeader
          onClick={openDrawer}
          profile={profile}
          refetchProfile={refetchProfile}
          profilePicture={profilePicture}
          refetchProfilePicture={refetchProfilePicture}
          profilePictureIsSuccess={profilePictureIsSuccess}
          profilePictureIsError={profilePictureIsError}
          profilePictureIsFetching={profilePictureIsFetching}
        />
        <LayoutCustomStyled>
          {drawer}
          <Layout.Sider
            breakpoint="lg"
            collapsedWidth="0"
            onCollapse={(collapsed, type) => {}}
            trigger={null}
            width={280}
            className="sider-primary ant-layout-sider-primary"
            style={{ background: "var(--background-color)", display: hideSidebar ? "none" : "block" }}
          >
            <DivCustomStyled>
              <LinkStyled to={"#"}>
                <IconStyled src={homeIcon} alt={"homeIcon"} style={{ width: "110px" }} />
              </LinkStyled>
            </DivCustomStyled>
            {menuSider}
          </Layout.Sider>

          <LayoutContentCustomStyled>
            <Outlet
              context={{
                profile,
                refetchProfile,
                profilePicture,
                refetchProfilePicture,
                profilePictureIsSuccess,
                profilePictureIsError,
                profilePictureIsFetching
              }}
            />
          </LayoutContentCustomStyled>
        </LayoutCustomStyled>
        <Layout.Footer style={{ background: "var(--background-color)", textAlign: "center" }}>
          ICON MORTGAGE DEVELOPER PORTAL
          <br />© 2023 ICON Framework CO.,Ltd.
        </Layout.Footer>
        {displayed && (
          <img src={totop} style={{ position: "fixed", bottom: 12, right: 12, cursor: "pointer", width: "40px" }} onClick={scrollToTop} />
        )}
      </Layout>
    </>
  );
};

export default MainLayout;

const LayoutStyled = styled(Layout)``;
const LayoutCustomStyled = styled(Layout)`
  height: 100%;
  background: var(--background-color);
  padding: 32px 24px 0px 24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const DivCustomStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 16px;
  border-radius: 50px;
  margin-bottom: 16px;
  background: #dee7ed;
`;

const LayoutContentCustomStyled = styled(Layout.Content)`
  background: var(--background-color);
  padding-left: 24px;
  padding-bottom: 24px;
  @media only screen and (${media.lg}) {
    padding-left: 0px;
  }
`;
