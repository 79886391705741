import { api } from "src/store/services/api";
import {
  ResponseNoti,
  RequestGetNoti,
  RequestGetTypeNoti,
  ResponseNotiCount,
  RequestReadAllNoti
} from "src/models/api/noti.model";

export const notiApi = api.enhanceEndpoints({ addTagTypes: ["Noti"] }).injectEndpoints({

  endpoints: (builder) => ({
    getNoti: builder.query<ResponseNoti, RequestGetNoti>({
      query: (Query) => ({
        url: "internals/master/noti",
        method: "POST",
        body: Query,
      }),
      providesTags: ["Noti"]
    }),
    getNotiMu: builder.query<ResponseNoti, RequestGetNoti>({
      query: (Query) => ({
        url: "internals/master/noti",
        method: "POST",
        body: Query,
      })
    }),
    readNoti: builder.mutation<void, number>({
      query: (id) => ({
        url: `internals/master/noti/read/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Noti"]
    }),
    readAllNoti: builder.mutation<void, RequestReadAllNoti>({
      query: (query) => ({
        url: `internals/master/noti/read/all`,
        method: "POST",
        body:query,
      }),
      invalidatesTags: ["Noti"]
    }),
    getTypeNoti: builder.query<ResponseNoti, RequestGetTypeNoti>({
      query: (Query) => ({
        url: `internals/master/noti/type`,
        method: "POST",
        body: Query
      })
    }),
    getNotiCount: builder.query<ResponseNotiCount,void>({
      query: () => ({
        url: `internals/master/noti/count`,
        method: "POST",
      }),
      providesTags: ["Noti"]
    })
  })
});

export const {
  useGetNotiQuery,
  useGetNotiMuQuery,
  useReadNotiMutation,
  useReadAllNotiMutation,
  useGetTypeNotiQuery,
  useGetNotiCountQuery
} = notiApi;

