import { Spin } from "antd";
import styled from "styled-components";
import useLoading from "src/hooks/useLoading";

function MasterLoader(props: any) {
  const { isLoading } = useLoading();

  return (
    <SpinStyled spinning={isLoading} size={"large"}>
      {props.children}
    </SpinStyled>
  );
}

export default MasterLoader;

const SpinStyled = styled(Spin)`
  .ant-spin-dot {
    font-size: 36px;
    color: var(--primary-color);
    position: -webkit-sticky; /* Safari */
    position: sticky !important;
  }
  .ant-spin-text {
    font-size: 16px;
    color: var(--primary-color);
  }
  min-height: 100%;
`;
