import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "src/store/services/authApi";
// import { authCheckTokenApi } from "src/store/services/authCheckTokenApi";
import { RootState } from "src/store/store";

import { User } from "src/models/api/auth.model";

interface InitialState {
  user: (User & { data: { token: string } }) | null;
  isAuthenticated: boolean;
  package: string;
}

const initialState: InitialState = {
  user: null,
  isAuthenticated: false,
  package: "non-interface"
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => initialState
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    });
    // builder.addMatcher(authApi.endpoints.fetchToken.matchFulfilled, (state, action) => {
    //   state.isAuthenticated = true;
    // });
  }
});

export const { logout } = slice.actions;
export default slice.reducer;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectUser = (state: RootState) => state.auth.user;
export const selectPackage = (state: RootState) => state.auth.package;