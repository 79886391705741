import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";


type Borrower = {
    customer_id: number | null;
    customer_code: string;
}

const initialValues: Borrower = {
    customer_id: Number(localStorage.getItem("@dev-portal/customer_id")) || null,
    customer_code: localStorage.getItem("@dev-portal/customer_code") || "",
}

export const borrowerSlice = createSlice({
    name: "borrowerSlice",
    initialState: initialValues,
    reducers: {
        updateBorrower: (state: Borrower, action: PayloadAction<{ value: any; type: string }>) => {
            if (action.type === updateBorrower.type) {
                return {
                    ...state,
                    [action.payload.type] : action.payload.value
                }
            }
        }
    }
});

export const { updateBorrower } = borrowerSlice.actions;
export default borrowerSlice.reducer;

export const selectCustomerId = (store: RootState) => store.borrower.customer_id;
export const selectCustomerCode = (store: RootState) => store.borrower.customer_code;
