import { string } from "prop-types";
import { CreateSettingRole, DataSettingRole, EditSettingRole, SettingRole, DataSettingRoleUser } from "src/models/api/settingRole.model";
import { apiSetting } from "src/store/services/api";

export const settingRolesApi = apiSetting.enhanceEndpoints({ addTagTypes: ["SettingRole", "SettingRolePermission2"] }).injectEndpoints({
  endpoints: (builder) => ({
    settingRoles: builder.query<DataSettingRole, void>({
      query: () => "internals/master/role",
      providesTags: ["SettingRole"]
    }),

    deleteSettingRole: builder.mutation<void, string | number>({
      query: (id) => ({
        url: `internals/master/role/${id}/delete`,
        method: "POST"
      }),
      invalidatesTags: ["SettingRole"]
    }),
    setActiveSettingRole: builder.mutation<void, any>({
      query: (body) => ({
        url: `internals/master/role/set-active`,
        method: "POST",
        body
      })
      // invalidatesTags: ["SettingRole"]
    }),
    createSettingRole: builder.mutation<void, CreateSettingRole>({
      query: (createSettingRole) => ({
        url: "internals/master/role/add",
        method: "POST",
        body: createSettingRole
      }),
      invalidatesTags: ["SettingRole"]
    }),
    updateSettingRole: builder.mutation<void, EditSettingRole>({
      query: (editSettingRole) => ({
        url: "internals/master/role/edit",
        method: "POST",
        body: editSettingRole
      }),
      invalidatesTags: ["SettingRole"]
    }),
    updateSettingRoleUsers: builder.mutation<void, { userId: []; roleId: number | string }>({
      query: (updateSettingRoleUsers) => ({
        url: "internals/master/role/user/add",
        method: "POST",
        body: updateSettingRoleUsers
      }),
      invalidatesTags: ["SettingRole"]
    }),
    // get role Permission

    getRolePermission: builder.query<any, string | number>({
      query: (id) => `internals/master/role-permission/${id}`,
      providesTags: ["SettingRolePermission2"]
    }),
    updateRolePermission: builder.mutation<void, any>({
      query: (data) => ({
        url: "internals/master/role-permission/add",
        method: "POST",
        body: data
      }),
      invalidatesTags: ["SettingRolePermission2"]
    }),
    setRolePermission: builder.mutation<void, any>({
      query: (data) => ({
        url: "internals/master/role-permission/main",
        method: "POST",
        body: data
      }),
      invalidatesTags: ["SettingRolePermission2"]
    })
  })
});

export const {
  useSettingRolesQuery,
  useDeleteSettingRoleMutation,
  useCreateSettingRoleMutation,
  useUpdateSettingRoleMutation,
  // useSettingRoleUsersQuery
  useUpdateSettingRoleUsersMutation,
  useGetRolePermissionQuery,
  useUpdateRolePermissionMutation,
  useSetRolePermissionMutation,
  useSetActiveSettingRoleMutation
} = settingRolesApi;
