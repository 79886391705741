import { Button } from "antd";
import styled from "styled-components";
import { fontWeight } from "src/styles/themes/constants";


function hexToRGB(hex: string) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return [r, g, b];
}

function getTextColor(bgColor: string) {
  const rgb = hexToRGB(bgColor);
  const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
  return brightness <= 125 ? "var(--white-color)" : "var(--black-color)";
}

export const ButtonPrimaryStyled = styled(Button).attrs(() => {
  return {
    type: "primary"
  };
})`
  background: ${(props) => props.color ?? "var(--primary-color)"};
  color: ${(props) => (props.color ? getTextColor(props.color) : "var(--white-color)")};
  :not(:disabled):hover {
    background: var(--dark-color);
    color: var(--white-color);
  }
  :not(:disabled):active {
    background: ${(props) => props.color ?? "var(--primary-color)"};
    color: ${(props) => (props.color ? getTextColor(props.color) : "var(--white-color)")};
  }
  &.ant-btn > span {
    font-weight: ${fontWeight.semibold};
    font-size: 14px;
  }
  display: flex;
  align-items: center;
  border-radius: 100px;
  box-shadow: 0px 2px 5px 1px rgba(23, 43, 77, 0.15);
  height: 35px;
  min-width: 80px;
  justify-content: space-around;
`;

export const ButtonDefaultStyled = styled(Button).attrs(() => {
  return {
    type: "default"
  };
})`
  color: ${(props) => props.color ?? "var(--primary-color)"};
  border-color: ${(props) => props.color ?? "var(--primary-color)"};
  :not(:disabled):hover {
    color: ${(props) => props.color ?? "var(--dark-color)"};
    border-color: ${(props) => props.color ?? "var(--dark-color)"};
  }
  :not(:disabled):active {
    color: ${(props) => props.color ?? "var(--primary-color)"};
    border-color: ${(props) => props.color ?? "var(--primary-color)"};
  }
  &.ant-btn > span {
    font-weight: ${fontWeight.semibold};
    font-size: 14px;
  }
  display: flex;
  align-items: center;
  border-radius: 100px;
  box-shadow: 0px 2px 5px 1px rgba(23, 43, 77, 0.15);
  height: 35px;
  min-width: 80px;
  justify-content: space-around;
`;
