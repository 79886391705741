import { Config, ResponseConfig } from "src/models/api/config.model";
import { api } from "./api";

export const configApi = api.enhanceEndpoints({ addTagTypes: [] }).injectEndpoints({
  endpoints: (builder) => ({
    config: builder.query<ResponseConfig, void>({
      query: () => "/config"
    }),
    newConfig: builder.query<any, void>({
      query: () => "/config"
    })
  })
});

export const { useConfigQuery, useNewConfigQuery } = configApi;
