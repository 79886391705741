import { ITheme } from "../../models/theme/itheme.model";

export const lightColorsTheme: ITheme = {
  background: "#f8f9fe",
  header: "linear-gradient(90deg, #415da7 0%, #7573be 100%)",
  login: "linear-gradient(89.96deg, #5e8ce4 11.99%, #705fe4 99.96%)",
  menu: "linear-gradient(89.96deg, #4DB6F1 0.02%, #886EF1 99.96%)",
  primary: "#6782EA",
  title: "#32325d",
  placeholder: "#bdbdbd",
  border: "#dee2e6",
  label: "#525f7f",
  input: "#525f7f",
  inputSelection: "#8898aa",
  inputIcon: "#8898aa",
  cancel: "#E5E9ED",
};
