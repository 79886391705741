import { useMemo } from "react";
import { useSelector } from "react-redux";
import { switchLoaderSelector } from "src/store/slice/loaderSlice";
const useLoading = () => {
    const isLoading = useSelector(switchLoaderSelector);

  return useMemo(() => {
    return {
        isLoading
    };
  }, [isLoading]);
};

export default useLoading;