import {
  Loan,
  CaseCreate,
  LoanUnit,
  LoanBorrower,
  LoanBorrowerAddress,
  LoanBorrowerInfomation,
  ResponseLoanRequest,
  LoanListQuery,
  ResponseLoanList,
  ResponseLoanUnitDetail,
  ResponseLoanDetail,
  ResponseLoanBorrower,
  ResponseBorrowerAddress,
  ResponseLoanBorrowerInformation,
  ResponseLoanBorrowerDetail,
  ResponseFiles,
  RequestFileList,
  ResponseStatus,
  ConsentUpdate,
  RemoveFileGroup,
  ResponseConsent,
  ResponseAddUnit,
  ResponseAddBorrowerInformation,
  RequestRecord,
  ResponseRecord,
  RequestLoanStatus,
  ResponseLoanStatus,
  DeleteBorrowerById,
  LoanBorrowerDetail,
  RequestMapCustomerCase,
  ResponseMapCustomerCase,
  ResponseCaseCreate,
  ResponseCancelCase,
  RequestCancelCase,
  ResponseBankCaseDetail,
  ResponseBankUnitDetail,
  ResponseBankLoanDetail,
  ResponseBankCollateralDetail,
  ResponseLogBank,
  RequestLogBank,
  ResponseBorrowerAdd,
  ResponseActivityLog,
  RequestActivityLog,
  ResponseLogLoanApprove,
  ResponseStatusMessage,
  RequestSelectBank
} from "src/models/api/loan.model";
import { api } from "./api";

export const loansApi = api
  .enhanceEndpoints({ addTagTypes: [
    "Loan",
    "LoanList", 
    "LoanDetail",
    "File", 
    "BorrowerDetail", 
    "BorrowerList", 
    "LoanApprove", 
    "LoanStatusList", 
    "BorrowerAddress",
    "LoanUnitDetail", 
    "ActivityLog",
    "Collateral",
    "RoomPlanView",
    "LoanView",
  ] })
  .injectEndpoints({
    endpoints: (builder) => ({
      loans: builder.query<Loan[], void>({
        query: () => "/loans"
      }),
      loan: builder.query<Loan, string>({
        query: (loan_id) => `/internals/loan/${loan_id}/detail`
      }),
      getLoanDetail: builder.query<ResponseLoanDetail, string>({
        query: (loan_id) => `/internals/loan/get/case/${loan_id}`,
        providesTags: ["LoanDetail"]
      }),
      getLoanUnitDetail: builder.query<ResponseLoanUnitDetail, { case_code: string; unit_code: string }>({
        query: (Query) => `/internals/loan/get/unit/${Query.unit_code}/${Query.case_code}`,
        providesTags: ["LoanUnitDetail"]
      }),
      requestLoan: builder.query<ResponseLoanRequest, void>({
        query: () => `/internals/loan/get/case-code`
      }),
      getLoanList: builder.query<ResponseLoanList, LoanListQuery>({
        query: (Query) => ({
          url: "/internals/loan/get/case_lists",
          method: "POST",
          body: Query
        }),
        providesTags:["LoanList"]
      }),
      getLoanStatusList: builder.query<ResponseLoanStatus, RequestLoanStatus>({
        query: (Query) => ({
          url: `/internals/loan/get/loan_bank_lists`,
          method: "POST",
          body: Query
        }),
        providesTags: ["LoanStatusList"]
      }),
      getBorrowerAddress: builder.query<ResponseBorrowerAddress, { case_code: string; customer_code: string }>({
        query: (Query) => `/internals/loan/get/customer/${Query.case_code}/${Query.customer_code}/address`,
        providesTags: ["BorrowerAddress"]
      }),
      addLoan: builder.mutation<ResponseCaseCreate, CaseCreate>({
        query: (query) => ({
          url: `/internals/loan/add/case/${query.case_code}`,
          method: "POST",
          body: query
        }),
        invalidatesTags:["RoomPlanView","LoanList","LoanStatusList"]
      }),
      addUnit: builder.mutation<ResponseAddUnit, LoanUnit>({
        query: (Unit) => ({
          url: `/internals/loan/add/unit/${Unit.unit_code}/${Unit.case_code}`,
          method: "POST",
          body: Unit
        }),
        invalidatesTags: ["LoanUnitDetail","Collateral"]
      }),

      getBorrowerList: builder.query<ResponseLoanBorrower, string>({
        query: (case_code) => `/internals/loan/get/customer/case/list/${case_code}`,
        providesTags: ["BorrowerList"]
      }),
      addBorrower: builder.mutation<ResponseBorrowerAdd, LoanBorrowerDetail>({
        query: (Borrower) => ({
          url: `/internals/loan/map/create/customer/${Borrower.case_code}`,
          method: "POST",
          body: Borrower
        }),
        invalidatesTags: ["BorrowerDetail","BorrowerList"]
      }),
      deleteBorrowerById: builder.mutation<ResponseStatus, DeleteBorrowerById>({
        query: (borrower) => ({
          url: `/internals/loan/delete/customer/${borrower.customer_code}/${borrower.case_code}`,
          method: "POST"
        }),
        invalidatesTags: ["BorrowerList"]
      }),
      addBorrowerAddress: builder.mutation<void, LoanBorrowerAddress>({
        query: (Address) => ({
          url: `/internals/loan/customer/${Address.case_code}/${Address.customer_code}/set/address`,
          method: "POST",
          body: Address
        }),
        invalidatesTags: ["BorrowerAddress"]
      }),
      addBorrowerInformation: builder.mutation<ResponseAddBorrowerInformation, LoanBorrowerInfomation>({
        query: (Information) => ({
          url: `/internals/loan/customer/${Information.case_code}/${Information.customer_code}/expenses/${Information.id}`,
          method: "POST",
          body: Information
        }),
        invalidatesTags: ["Loan"]
      }),
      getBorrowerInformation: builder.query<ResponseLoanBorrowerInformation, { case_code: string; customer_code: string }>({
        query: (Query) => `/internals/loan/get/customer/${Query.case_code}/${Query.customer_code}/expenses`,
        providesTags: ["Loan"]
      }),
      getBorrowerDetail: builder.query<ResponseLoanBorrowerDetail, { case_code: string; customer_code: string }>({
        query: (Query) => `/internals/loan/get/customer/${Query.case_code}/${Query.customer_code}`,
        providesTags: ["BorrowerDetail"]
      }),
      addFile: builder.mutation<void, FormData>({
        query: (File) => ({
          url: "/internals/files",
          method: "POST",
          body: File
        }),
        invalidatesTags: ["File","LoanStatusList"]
      }),
      getFileList: builder.query<ResponseFiles, RequestFileList>({
        query: (Query) => ({
          url: "/internals/files/list",
          method: "POST",
          body: Query
        }),
        providesTags: ["File"]
      }),
      deleteFile: builder.mutation<ResponseStatus, { file_id: string; customer_id: number; bank_code: unknown }>({
        query: (Query) => ({
          url: `/internals/files/delete`,
          method: "POST",
          body: Query
        }),
        invalidatesTags: ["File","LoanStatusList"]
      }),
      deletFileGroup: builder.mutation<ResponseStatus, RemoveFileGroup>({
        query: (Group) => ({
          url: `/internals/files/delete/group`,
          method: "POST",
          body: Group
        }),
        invalidatesTags: ["File","LoanStatusList"]
      }),
      newDeleteFile: builder.mutation<ResponseStatus, any>({
        query: (body) => ({
          url: `/internals/files/delete`,
          method: "POST",
          body
        }),
        invalidatesTags: ["File"]
      }),
      updateConsent: builder.mutation<ResponseStatus, ConsentUpdate>({
        query: (query) => ({
          url: `/internals/loan/update/customer/${query.case_code}/${query.customer_code}/consent`,
          method: "POST",
          body: {
            is_consent: query.is_consent
          }
        })
      }),
      getConsent: builder.query<ResponseConsent, number | null>({
        query: (customer_id) => ({
          url: `/internals/loan/get/consent/${customer_id}`
        })
      }),
      updateLoan: builder.mutation<void, Loan>({
        query: ({ id, ...rest }) => ({
          url: `/loans/${id}`,
          method: "PUT",
          body: rest
        }),
        invalidatesTags: ["Loan"]
      }),
      deleteLoan: builder.mutation<void, string>({
        query: (id) => ({
          url: `/loans/${id}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Loan"]
      }),
      getFileById: builder.mutation<any, number>({
        query: (id) => ({
          url: `/internals/files/${id}`,
          method: "POST",
          responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
          cache: "no-cache"
        })
      }),
      record: builder.mutation<ResponseRecord, RequestRecord>({
        query: (Query) => ({
          url: `/internals/logs/history`,
          method: "POST",
          body: Query
        })
      }),
      mapCustomerCase: builder.mutation<ResponseMapCustomerCase, RequestMapCustomerCase>({
        query: (Query) => ({
          url: `/internals/loan/map/customer/${Query.case_code}/`,
          method: "POST",
          body: {
            customer_data: Query.customer_data
          }
        })
      }),
      chooseBank: builder.mutation<void, any>({
        query: (body) => {
          return {
            url: `/internals/loan/choose-bank`,
            method: "POST",
            body
          };
        },
        invalidatesTags: ["LoanStatusList"]
      }),
      unChooseBank: builder.mutation<void, any>({
        query: (body) => {
          return {
            url: `/internals/loan/unchoose-bank`,
            method: "POST",
            body
          };
        },
        invalidatesTags: ["LoanStatusList"]
      }),
      bankGetDocListCaseCode: builder.query<any, string>({
        query: (case_code) => {
          return {
            url: `/internals/loan/bank/get/doc-list/${case_code}`,
            method: "GET"
          };
        },
        providesTags: ["LoanStatusList"]
      }),
      bankGetDocListHistory: builder.mutation<any, any>({
        query: (body) => {
          return {
            url: `/internals/loan/bank/get/doc-list/history`,
            method: "POST",
            body
          };
        },
        invalidatesTags: ["LoanStatusList"]
      }),
      sendDocument: builder.mutation<any, { case_code?: string; banks_code: any }>({
        query: (data) => ({
          url: `internals/loan/bank/send_document/${data?.case_code}`,
          method: "POST",
          body: {
            banks_code: data.banks_code
          }
        }),
        invalidatesTags: ["LoanStatusList","LoanList","ActivityLog"]
      }),
      bankGetDocListCaseCodeDate: builder.mutation<any, any>({
        query: (body) => {
          return {
            url: `/internals/loan/bank/send/doc-list/date`,
            method: "POST",
            body
          };
        },
        invalidatesTags: ["LoanStatusList"]
      }),
      cancelCaseByBank: builder.mutation<ResponseCancelCase, RequestCancelCase>({
        query: (Params) => ({
          url: `/internals/loan/bank/cancel/${Params.loan_id}/${Params.bank_code}`,
          method: "POST",
          body: Params
        }),
        invalidatesTags: ["LoanStatusList","LoanList","ActivityLog"]
      }),
      bankCaseDetail: builder.query<ResponseBankCaseDetail, string>({
        query: (loan_id) => `/internals/loan/get/bank/${loan_id}`
      }),
      bankUnitDetail: builder.query<ResponseBankUnitDetail, string>({
        query: (case_code) => `/internals/loan/get/bank/unit/${case_code}`,
        providesTags:["RoomPlanView"]
      }),
      bankLoanDetail: builder.query<ResponseBankLoanDetail, string>({
        query: (loan_id) => `/internals/loan/get/bank/loan/${loan_id}`,
        providesTags:["LoanView"]
      }),
      bankCollateralDetail: builder.query<ResponseBankCollateralDetail, string>({
        query: (case_code) => `/internals/loan/get/bank/collateral/${case_code}`,
        providesTags:["Collateral"]
      }),
      getBankLogLoan: builder.query<ResponseLogBank, RequestLogBank>({
        query: (Query) => `/internals/loan/get/log/bank/${Query.case_code}/${Query.bank_code}`,
      }),
      getActivityLog: builder.query<ResponseActivityLog, RequestActivityLog>({
        query: (Query) => ({
          url: `/internals/loan/get/log/case/${Query.case_code}`,
          method: "POST",
          body: Query
        }),
        providesTags: ["ActivityLog"]
      }),
      selectBank: builder.mutation<ResponseStatusMessage, RequestSelectBank>({
        query: (Params) => ({
          url: `/internals/loan/bank/select/banks`,
          method: "POST",
          body: Params
        }),
        invalidatesTags: ["LoanDetail","ActivityLog"]
      }),
      getLogLoanApprove: builder.query<ResponseLogLoanApprove, string>({
        query: (case_code) => ({
          url: `/internals/loan/get/log/loan/approve/${case_code}`
        }),
        providesTags: ["LoanApprove"]
      }),
      unselectBank: builder.mutation<ResponseStatusMessage, { case_code: string; bank_code: string }>({
        query: (Query) => ({
          url: `/internals/loan/bank/unselect/banks`,
          method: "POST",
          body: Query
        }),
        invalidatesTags: ["LoanDetail","ActivityLog"]
      }),
      closeCase: builder.mutation<ResponseStatusMessage, string>({
        query: (case_code) => ({
          url: `/internals/loan/bank/closes/${case_code}`,
          method: "POST"
        }),
        invalidatesTags: ["LoanApprove", "LoanList", "LoanStatusList","ActivityLog"]
      }),
      cancelCase: builder.mutation<ResponseStatusMessage, string>({
        query: (case_code) => ({
          url: `/internals/loan/bank/cancel/all/bank/${case_code}`,
          method: "POST"
        }),
        invalidatesTags: ["LoanApprove", "LoanList", "LoanStatusList","ActivityLog"]
      })
    })
  });

export const {
  useLoansQuery,
  useLoanQuery,
  useGetLoanDetailQuery,
  useGetLoanUnitDetailQuery,
  useLazyRequestLoanQuery,
  useGetLoanListQuery,
  useGetLoanStatusListQuery,
  useGetBorrowerAddressQuery,
  useAddLoanMutation,
  useAddUnitMutation,
  useGetBorrowerListQuery,
  useDeleteBorrowerByIdMutation,
  useAddBorrowerMutation,
  useAddBorrowerAddressMutation,
  useAddBorrowerInformationMutation,
  useGetBorrowerInformationQuery,
  useGetBorrowerDetailQuery,
  useAddFileMutation,
  useGetFileListQuery,
  useDeleteFileMutation,
  useNewDeleteFileMutation,
  useDeletFileGroupMutation,
  useUpdateConsentMutation,
  useGetConsentQuery,
  useUpdateLoanMutation,
  useDeleteLoanMutation,
  useGetFileByIdMutation,
  useRecordMutation,
  useMapCustomerCaseMutation,
  useChooseBankMutation,
  useUnChooseBankMutation,
  useBankGetDocListCaseCodeQuery,
  useBankGetDocListHistoryMutation,
  useSendDocumentMutation,
  useCancelCaseByBankMutation,
  useBankCaseDetailQuery,
  useBankUnitDetailQuery,
  useBankLoanDetailQuery,
  useBankCollateralDetailQuery,
  useGetBankLogLoanQuery,
  useGetActivityLogQuery,
  useSelectBankMutation,
  useGetLogLoanApproveQuery,
  useUnselectBankMutation,
  useCloseCaseMutation,
  useCancelCaseMutation,
  useBankGetDocListCaseCodeDateMutation
} = loansApi;
